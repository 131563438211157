import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';
import {BsWhatsapp} from 'react-icons/bs';
import {SiGmail} from 'react-icons/si';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const handleClickInstagram= () => {
  window.open("https://www.instagram.com/la_esquina_bar_and_lounge/");
};

const handleClickFacebook= () => {
  window.open("https://www.instagram.com/la_esquina_bar_and_lounge/");
};
const handleClickTwitter= () => {
  window.open("https://twitter.com/LoungeEsquina");
};
const buttonStyle = {
  backgroundColor: "transparent",
  backgroundRepeat:"no-repeat",
  border: "none",
  cursor: "pointer",
  overflow: "hidden",
  outline: "none"
};

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contacto</h1>
        <p className="p__opensans">Av 25 De Febrero 40, Santo Domingo Este 11606, Dominican Republic</p>
        <p className="p__opensans"><BsWhatsapp/> +1 781-526-6937</p>
        <p className="p__opensans"><BsWhatsapp/> +1 809-219-1809</p>
        <p className="p__opensans"><SiGmail/>  laesquinaajpbarandlounge@gmail.com</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.gericht} alt="footer_logo" />
        <p className="p__opensans">&quot;La mejor manera de encontrarte a ti mismo es perderte al servicio de los demás..&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
        <button type="button" onClick={handleClickFacebook} style={buttonStyle}> <FiFacebook /></button>
        <button type="button" onClick={handleClickTwitter} style={buttonStyle}> <FiTwitter /></button>
        <button type="button" onClick={handleClickInstagram} style={buttonStyle}> <FiInstagram /></button>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Horas de Oficina</h1>
        <p className="p__opensans">Jueves - Viernes:</p>
        <p className="p__opensans"> 4:00 PM - 02:00 AM</p>
        <p className="p__opensans">Sábado - Domingo:</p>
        <p className="p__opensans"> 4:00 PM - 03:00 AM</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">&#169; 2021 LaEsquina. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
