import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">QUIÉNES SOMOS</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Ofrecemos bebidas de alta calidad, con suficiente variedad para satisfacer al público. Tenemos buena música y un DJ increíble todas las noches para crear el modo y puedes disfrutar de una gran variedad de cócteles.</p>
        {/*<button type="button" className="custom__button">Know More</button>*/ }
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Servicios</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Ofrecemos los siguientes servicios:<br/> 
            - Nosotros organizamos evento mensual. <br/>
            - Puede reservar el espacio para eventos privados como bodas, celebraciones de cumpleaños, graduaciones, etc.<br/>
            - Noche de stripper todos los sábados. <br/>
            - Noche de karaoke el viernes. <br/>
            - Bar con variedad de bebidas y sabroso coctel <br/>
            <br/>
        </p>
        <button type="button" className="custom__button">Aprende más</button>
      </div>
    </div>
  </div>
);

export default AboutUs;
