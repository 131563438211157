import React from 'react';

import  SubHeading from '../../components/SubHeading/SubHeading'
import MenuItem from '../../components/Menuitem/MenuItem';
import { data, images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menú que se adapta a tu paladar" />
      <h1 className="headtext__cormorant">Hoy es especial</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Cerveza</p>
        <div className="app__specialMenu_menu_items">
          {data.wines.map((wine, index) => (
            <MenuItem key={wine.title + index} title={wine.title} price={wine.price} tags={wine.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Cocteles</p>
        <div className="app__specialMenu_menu_items">
          {data.cocktails.map((cocktail, index) => (
            <MenuItem key={cocktail.title + index} title={cocktail.title} price={cocktail.price} tags={cocktail.tags} />
          ))}
        </div>
      </div>
    </div>



    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Hookah</p>
        <div className="app__specialMenu_menu_items">
          {data.hookas.map((hooka, index) => (
            <MenuItem key={hooka.title + index} title={hooka.title} price={hooka.price} tags={hooka.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.hookahpic} alt="hookah__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Botellas</p>
        <div className="app__specialMenu_menu_items">
          {data.botellas.map((botella, index) => (
            <MenuItem key={botella.title + index} title={botella.title} price={botella.price} tags={botella.tags} />
          ))}
        </div>
      </div>
    </div>

  </div>
);

export default SpecialMenu;
