import React from 'react';

import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';

const handleClick = () => {
  window.open("https://www.instagram.com/la_esquina_bar_and_lounge/");
};

const Newsletter = () => (
  <div className="app__newsletter" id="reservation">
    <div className="app__newsletter-heading">
      <SubHeading title="Reservar" />
      <h1 className="headtext__cormorant">Para Todas Las Reservas</h1>
      <p className="p__opensans">- Si necesita reservar todo el lugar para organizar un evento privado como una fiesta de 
      cumpleaños, graduación, ceremonia de boda, 
      contáctenos a través de whatsapp o correo electrónico. <br/>
      <br/>
      - Para nuestros eventos programados, puede contactarnos a través de whatsapp o por correo electrónico o 
      puede visitarnos en nuestra ubicación. Ver toda la información de contacto a continuación.  
      <br/>    
      </p>
    </div>
    <div className="app__newsletter-input flex__center">
      <button type="button" onClick={handleClick} className="custom__button">Todos Los Eventos</button>
    </div>
  </div>
);

export default Newsletter;
