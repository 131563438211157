import React from 'react';

import AboutUs from './container/AboutUs/AboutUs';
import FindUs  from './container/Findus/FindUs';
import Footer  from './container/Footer/Footer';
import Gallery from './container/Gallery/Gallery';
import Header from './container/Header/Header';
import SpecialMenu from './container/Menu/SpecialMenu';
import Navbar  from './components/Navbar/Navbar';
import './App.css';

const App = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    <SpecialMenu />
    <Gallery />
    <FindUs />
    <Footer />
  </div>
);

export default App;
