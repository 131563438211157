import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/Cocktail-PNG-Transparent-Image.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome1.JPG';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/esquinapng-250size.png';
import hookahpic from '../assets/pngwingcom.png';
import bottle from '../assets/34407-8-champagne-popping-transparent.png';
import gallery6 from '../assets/gallery6.JPG';
import gallery7 from '../assets/gallery7.JPG';
import gallery8 from '../assets/gallery8.JPG';
import gallery9 from '../assets/gallery9.JPG';
import gallery10 from '../assets/gallery10.JPG';
import gallery11 from '../assets/gallery11.JPG';

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  hookahpic, 
  bottle, 
 gallery6, 
 gallery7,
 gallery8,
 gallery9,
 gallery10,
gallery11
};
