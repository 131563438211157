import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import {BrowserRouter} from "react-router-dom";
import {HashLink as Link} from "react-router-hash-link";
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <BrowserRouter>
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.gericht} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">HOGAR</a></li>
        <li className="p__opensans"><a href="#about">QUIÉNES SOMOS</a></li>
        <li className="p__opensans"><a href="#menu">MENÚ</a></li>
        <li className="p__opensans"><a href="#contact">CONTACTO</a></li>
      </ul>
      <div className="app__navbar-login">
        <div />
        <Link to="#reservation" smooth onClick={() => setToggleMenu(false)} className="p__opensans">Reservar</Link>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <ul className="app__navbar-smallscreen_links">
              <li><Link to="#home" smooth onClick={() => setToggleMenu(false)}>HOGAR</Link></li>
              <li><Link to="#about" smooth onClick={() => setToggleMenu(false)}>QUIÉNES SOMOS</Link></li>
              <li><Link to="#menu" smooth onClick={() => setToggleMenu(false)}>MENÚ</Link></li>
              <li><Link to="#contact" smooth onClick={() => setToggleMenu(false)}>CONTACTO</Link></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
    </BrowserRouter>
  );
};

export default Navbar;
