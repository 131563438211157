import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const handleClick = () => {
  window.open("https://www.google.com/maps/dir/42.5263711,-71.1329683/La+Esquina+Bar+and+Lounge/@30.1942224,-79.5138605,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x8eaf89a29966e417:0x5d09d4b08e62dc70!2m2!1d-69.8690278!2d18.4828206?hl=en");
};

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Contacto" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Find Us</h1>
      <div className="app__wrapper-content">
        <p className="p__opensans">Av 25 de Febrero 40, Santo Domingo Este 11606, Dominican Republic</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Horas de Oficina</p>
        <p className="p__opensans">Jueves - Viernes: 4:00 pm - 02:00 am</p>
        <p className="p__opensans">Sábado - Domingo: 4:00 pm - 03:00 am</p>
      </div>
      <button type="button" onClick={handleClick} className="custom__button" style={{ marginTop: '2rem' }}>Visítanos</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
