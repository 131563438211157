import images from './images';

const wines = [
  {
    title: 'PRESIDENTE',
    price: 'RD$ 100',
    tags: 'Pequena | Mediana | Jumbo',
  },
  {
    title: 'MODELO',
    price: 'RD$ 200',
    tags: 'Mediana',
  },
  {
    title: 'HEINEKEN',
    price: 'RD$ 200',
    tags: 'Mediana | Jumbo',
  },
  {
    title: 'CORONA',
    price: 'RD$ 200',
    tags: 'Pequena | Mediana | Jumbo',
  },
  {
    title: 'BRAHMA',
    price: 'RD$ 150',
    tags: 'Mediana',
  },
  {
    title: 'BOHEMIA',
    price: 'RD$ 150',
    tags: 'Mediana',
  },
  {
    title: 'ONE',
    price: 'RD$ 100',
    tags: 'Pequena | Mediana',
  },
];

const botellas = [
  {
    title: 'HENNESSY (Regular/VSOP)',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },

  {
    title: 'Brugal (Blanco/Anejo) ',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },

  {
    title: 'REMY MARTIN',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },

  {
    title: 'DON JULIO',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },
  {
    title: 'Patron',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },
  {
    title: 'Barcelo',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },
  {
    title: 'Bermundez',
    price: 'RD$ --',
    tags: 'Shots | Botella ',
  },
];

const cocktails = [
  {
    title: 'MARGARITA',
    price: 'RD$ 270',
    tags: 'Cachaca Brasilena | Fresas Frescas | Jugo de Toronja | Lima',
  },
  {
    title: "Oscura Y Tormentosa",
    price: 'RD$ 200',
    tags: 'Anejo | Jengibre | Rodaja de Lima',
  },
  {
    title: 'MOJITO',
    price: 'RD$ 200',
    tags: 'Grey Goose | Grand Marnier | Adornado con uvas blancas congeladas',
  },
  {
    title: 'Pina Colada',
    price: 'RD$ 270',
    tags: 'Tequila | Crema de platano | Crema blanca de cacao y jugo de limon fresco',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

const hookas = [
  {
    title: 'Sabor único',
    price: 'RD$ 300',
    tags: 'Menta | Pina | Naranja | Vanilla | Weed',
  },

  {
    title: 'Mezcla de dos sabores',
    price: 'RD$ 350',
    tags: 'Menta | Pina | Naranja | Vanilla | Weed',
  },

  {
    title: 'Mezcla de tres sabores',
    price: 'RD$ 400',
    tags: 'Menta | Pina | Naranja | Vanilla | Weed',
  },

  {
    title: 'Rellenar',
    price: 'RD$ 100',
    tags: '--',
  },

  {
    title: 'Cambio de carbono',
    price: 'RD$ 50',
    tags: '--',
  },

];

export default { wines, cocktails, awards, botellas, hookas};
